<template>
  <login-layout>
    <template slot="body">
      <div class="sign-in-screen">
        <div class="gradient-bg-section" />
        <div class="sign-in-screen__inner">
          <div class="titles-section">
            <span class="title">
              {{ $t('pages.signIn.title') }}
            </span>
            <span class="subtitle">
              {{ $t('pages.signIn.subtitle') }}
            </span>
          </div>
          <div
            class="sign-in-screen__main"
            @keyup.enter="handleSignIn()"
          >
            <VerticalTitleInput
              :title="$t('pages.signIn.inputs.email.value')"
              :placeholder="$t('pages.signIn.inputs.email.placeholder')"
              :value="email"
              :valid="this.validEmail"
              :error-label="$t('components.input.missingField')"
              @input="(e) => handleInput('email', e)"
            />
            <VerticalTitleInput
              type="password"
              :title="$t('pages.signIn.inputs.password.value')"
              :placeholder="$t('pages.signIn.inputs.password.placeholder')"
              :value="password"
              :valid="this.validPassword"
              :error-label="$t('components.input.missingField')"
              @input="(e) => handleInput('password', e)"
            />
            <div class="sign-in-screen__forgot-password">
              <BaseCheckbox
                :label="$t('pages.signIn.rememberMe')"
                :value="rememberMe"
                @input="(e) => handleInput('rememberMe', e)"
              />
              <router-link to="/forgot-password">
                {{ $t('pages.signIn.forgotPassword') }}
              </router-link>
            </div>
            <BaseButton
              large
              rectangular
              :label="$t('pages.signIn.loginButton')"
              class="login-button"
              @click="handleSignIn"
            />
            <div class="sign-in-screen__buttons">
              <BaseButton
                large
                rectangular
                @click="handleGoogleSignIn"
              >
                <template slot>
                  <img :src="require(`@/assets/svgs/socials/google.svg`)">
                  {{ $t('pages.signIn.google') }}
                </template>
              </BaseButton>
              <BaseButton
                large
                rectangular
                @click="handleFacebookSignIn"
              >
                <template slot>
                  <img :src="require(`@/assets/svgs/socials/facebook.svg`)">
                  {{ $t('pages.signIn.facebook') }}
                </template>
              </BaseButton>
            </div>
          </div>
          <span
            class="sign-in-screen__sign-up"
            v-html="$t('pages.signIn.register')"
          />
        </div>
      </div>
    </template>
  </login-layout>
</template>
<script>
import {
  VerticalTitleInput,
  BaseButton,
  BaseCheckbox,
} from 'ff-components-lib';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
} from 'firebase/auth';
import LoginLayout from '@/components/layout/login/LoginLayout';

export default {
  name: 'SignInScreen',
  components: {
    LoginLayout,
    VerticalTitleInput,
    BaseButton,
    BaseCheckbox,
  },
  data() {
    return {
      email: undefined,
      password: undefined,
      rememberMe: undefined,
      firebaseApp: undefined,
      firebaseAuth: undefined,
    };
  },
  computed: {
    validEmail() {
      return (
        this.email === undefined
        || (typeof this.email === 'string' && this.email.length > 0)
      );
    },
    validPassword() {
      return (
        this.password === undefined
        || (typeof this.password === 'string' && this.password.length > 0)
      );
    },
    validFields() {
      return (
        this.email && this.validEmail && this.password && this.validPassword
      );
    },
  },
  async mounted() {
    const firebaseConfig = {
      apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
      authDomain: 'firstfloor.firebaseapp.com',
      projectId: 'firstfloor',
      storageBucket: 'firstfloor.appspot.com',
      messagingSenderId: process.env.VUE_APP_FIREBASE_SENDER_ID,
      appId: process.env.VUE_APP_FIREBASE_APP_ID,
      measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
    };
    this.firebaseApp = initializeApp(firebaseConfig);
    this.firebaseAuth = getAuth(this.firebaseApp);
  },
  methods: {
    handleInput(field, value) {
      this[field] = value;
    },
    resetFields() {
      this.email = undefined;
      this.password = undefined;
    },
    async handleSignIn() {
      if (!this.validFields) {
        this.email = this.email || '';
        this.password = this.password || '';
        await this.$store.dispatch('displayAlert', {
          text: this.$t('components.alerts.loginFail'),
          type: 'error',
        });
      } else {
        try {
          await this.$store.dispatch('signIn', {
            email: this.email,
            password: this.password,
          });

          if (this.$route.params.redirect) {
            this.$router.replace({
              name: this.$route.params.name,
              params: this.$route.params.params,
            });
          } else {
            this.$router.push({ path: '/' });
          }

          this.resetFields();
        } catch (e) {
          await this.$store.dispatch('displayAlert', {
            text: this.$t('components.alerts.loginFail'),
            type: 'error',
          });
        }
      }
    },
    async handleGoogleSignIn() {
      try {
        const googleAuthProvider = new GoogleAuthProvider();
        this.firebaseAuth.useDeviceLanguage();
        const res = await signInWithPopup(
          this.firebaseAuth,
          googleAuthProvider,
        );

        /* const credential = GoogleAuthProvider.credentialFromResult(res);
        const token = credential.accessToken; */

        // The signed-in user info.
        const { user } = res;
        // IdP data available using getAdditionalUserInfo(result)

        await this.$store.dispatch('googleSignIn', user);

        this.$router.push({ path: '/' });

        this.resetFields();
      } catch (e) {
        console.log(e);
        await this.$store.dispatch('displayAlert', {
          text: this.$t('components.alerts.loginFail'),
          type: 'error',
        });
      }
    },
    async handleFacebookSignIn() {
      try {
        const facebookAuthProvider = new FacebookAuthProvider();
        this.firebaseAuth.useDeviceLanguage();
        const res = await signInWithPopup(
          this.firebaseAuth,
          facebookAuthProvider,
        );

        /* const credential = GoogleAuthProvider.credentialFromResult(res);
        const token = credential.accessToken; */

        // The signed-in user info.
        const { user } = res;
        console.log(res);
        console.log(user);
        // IdP data available using getAdditionalUserInfo(result)

        /* await this.$store.dispatch('facebookSignIn', user);

        this.$router.push({ path: '/' });

        this.resetFields(); */
      } catch (e) {
        console.log(e);
        await this.$store.dispatch('displayAlert', {
          text: this.$t('components.alerts.loginFail'),
          type: 'error',
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.sign-in-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 196px);

  .gradient-bg-section {
    position: absolute;
    width: 100%;
    height: 80%;
    background: linear-gradient(
      180deg,
      rgba($primary, 0.3) 0%,
      rgba($primary, 0) 100%
    );
    top: 100px;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 48px;
    z-index: 10;
    margin-top: 105px;
    margin-bottom: 135px;

    .login-button {
      font-size: 18px;
      font-weight: 600;
    }

    ::v-deep .base-input {
      box-shadow: 0px 4px 10px 0px rgba($primary, 0.1);
    }

    .titles-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 16px;

      .title {
        font-size: 36px;
        font-weight: 600;
        letter-spacing: -0.72px;
      }

      .subtitle {
        color: $grey;
        font-size: 20px;
        font-weight: 500;
      }
    }

    .sign-in-screen__main {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .sign-in-screen__forgot-password {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: -8px;

        ::v-deep .base-checkbox__input {
          width: 20px;
          height: 20px;

          &:after {
            top: 0;
          }
        }

        ::v-deep .base-checkbox__label {
          font-size: 14px;
        }

        a {
          font-size: 14px;
          font-weight: 600;
        }
      }

      .sign-in-screen__buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 24px;

        ::v-deep .base-button {
          width: 100%;
          background-color: white;
          color: $text-color;

          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
        }
      }
    }

    .sign-in-screen__sign-up {
      text-align: center;
      font-size: 14px;
      font-weight: 500;

      ::v-deep a {
        font-weight: 600;
      }
    }
  }
}

@media only screen and (max-width: 970px) {
  .sign-in-screen {
    &__inner {
      width: 76%;
      gap: 40px;
      margin-top: 100px;
      margin-bottom: 100px;
    }
  }
}

@media only screen and (max-width: 770px) {
  .sign-in-screen {
    min-height: calc(100vh - 174px);

    .gradient-bg-section {
      top: 70px;
    }

    &__inner {
      width: 84%;
      gap: 40px;
      margin-top: 48px;
      margin-bottom: 48px;

      .login-button {
        font-size: 16px;
      }

      .titles-section {
        gap: 8px;

        .title {
          font-size: 24px;
          letter-spacing: -0.48px;
        }

        .subtitle {
          font-size: 16px;
        }
      }

      .sign-in-screen__main {
        .sign-in-screen__forgot-password {
          ::v-deep .base-checkbox__input {
            width: 20px;
            height: 20px;

            &:after {
              top: 0;
            }
          }
        }

        .sign-in-screen__buttons {
          flex-direction: column;
          gap: 12px;
        }
      }
    }
  }
}
</style>
